import React from 'react';
import './footer.css';
import Logo from '../images/covington_logo.png';
// import App from '../images/istockphoto-875714464-2048x2048.jpg';
import Gmap from '../component/gmap';

function footer() {

  

  return (
    <>
    {/* <div className='footer-app-img'>
      <h1>Make An Appointment Today</h1>
      <button>Schedule Now</button>
    </div> */}
    <div className='footer-background'>
    <div className='footer-body'>
        {/* <div className='footer-logo'>
      <img id='logo_img_footer' src={Logo} alt="spine" />
      <h1>Covington Chiropractic</h1>
    </div> */}
        {/* <div className='logo'>
        <img id='logo_img' src={Logo} alt="spine" />
        <h1 id='logo'>Covington Chiropractic</h1>
    </div> */}
        <div className='footer-contact3'>
          <div className='footer-contact'>
            <h1 id='footer-h1'>Contact</h1>
            <h4>Phone: (253) 630-9777</h4>
            <h4>27641 Covington Way SE, Suite 2</h4>
            <h4>Covington, WA 98042</h4>
          </div>
          <div className='services-footer'>
            <h1 id='footer-h1'>Services</h1>
            <a id='footer-a' href="/chiropractic-care">Chiropractic Care</a>
            <a id='footer-a' href="/sports-chiropractic">Sports Chiropractic</a>
            <a id='footer-a' href="/personal-injury">Personal Injury</a>
            <a id='footer-a' href="/klaser-treatment">K Laser Treatment</a>
            <a id='footer-a' href="/pediatric-chiropractic">Pediatric Chiropractic</a>
            <a id='footer-a' href="/pregnancy-chiropractic">Pregnancy Chiropractic</a>
            <a id='footer-a' href="/auto-accident-chiropractic">Auto Accident Chiropractic</a>
            <a id='footer-a' href="/senior-chiropractic">Senior Chiropractic</a>
          </div>
        </div>
        <div className='footer-map'>
          <h1 id='footer-h1'>Location</h1>
          <Gmap/>
        </div>
        </div>
      </div></>
  )
}

export default footer