import React, { useState } from 'react';
import './header.css';
import Drawer from '../component/Drawer';
import Hamburger from 'hamburger-react';
import Logo from '../images/covington_logo.png';
import { AiFillPhone,AiFillMail } from "react-icons/ai";
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';

export default function Header() {

  const [isOpen, setOpen] = useState(false)

  const openDrawer = () => {
    // e.preventDefault();
    setOpen(<Drawer/>);
  }


  return (
    <>
    <div className='background3'>
    {/* <div className="large-monitor2"> */}
        <div className='contact-top'>
            <div className='contact-top2'>
                <a href="/contact-us"><button id='request-appointment-button'>Request Appointment</button></a>
                <Dropdown>
                <MenuButton id='office-hours-button'>Office Hours</MenuButton>
                <Menu>
                    <div className='footer-hours-dropdown'>
                            <div className='footer-hours-time'>
                                <div>
                                    <h5 id='footer-day'></h5>
                                </div>
                                <div>
                                    <h5 id='footer-day2'>Morning</h5>
                                </div>
                                <div>
                                    <h5>Afternoon</h5>
                                </div>
                                
                            </div>
                            <div className='footer-hours-time'>
                                <div>
                                    <h5 id='footer-day'>Monday:</h5>
                                </div>
                                <div>
                                    <h5 id='footer-day2'>9:00 - 1:00</h5>
                                </div>
                                <div>
                                    <h5>2:30 - 6:00</h5>
                                </div>
                            </div>
                            <div className='footer-hours-time'>
                                <div>
                                    <h5 id='footer-day'>Tuesday:</h5>
                                </div>
                                <div>
                                    <h5 id='footer-day2'>9:00 - 1:00</h5>
                                </div>
                                <div>
                                    <h5>2:30 - 6:00</h5>
                                </div>
                            </div>
                            <div className='footer-hours-time'>
                                <div>
                                    <h5 id='footer-day'>Wednesday:</h5>
                                </div>
                                <div>
                                    <h5 id='footer-day2'>9:00 - 1:00</h5>
                                </div>
                                <div>
                                    <h5>2:30 - 6:00</h5>
                                </div>
                            </div>
                            <div className='footer-hours-time'>
                                <div>
                                    <h5 id='footer-day'>Thursday:</h5>
                                </div>
                                <div>
                                    <h5 id='footer-day2'>9:00 - 1:00</h5>
                                </div>
                                <div>
                                    <h5>2:30 - 6:00</h5>
                                </div>
                            </div>
                            <div className='footer-hours-time'>
                                <div>
                                    <h5 id='footer-day'>Friday:</h5>
                                </div>
                                <div>
                                    <h5 id='footer-day2'>9:00 - 12:00</h5>
                                </div>
                                <div>
                                    <h5>12:00 - 2:00</h5>
                                </div>
                            </div>
                    </div>
                </Menu>
                </Dropdown>
            </div>
            <div className='header-phone3'>
                  <AiFillPhone id='phone3'/>
                  <a href='tel:2536309777'><h3>(253) 630-9777</h3></a>
              </div>
        </div>
    </div>
    <div className='background'>
    {/* <div className="large-monitor2"> */}
        <div className='header'>
        <a href='/'>
            <div className='logo'>
                <img id='logo_img' src={Logo} alt="spine" />
                <h1 id='logo3'>Covington Chiropractic</h1>
            </div>
        </a>
        <div className='links3'>
            
                <a href='/about-us' id='links'>About Us</a>
            
                <a href='/meet-our-doctors' id='links'>Meet Our Doctors</a>
            
                <a href='/our-services' id='links'>Our Services</a>
            
                <a href='/new-patients' id='links'>New Patients</a>
            
                <a href='/contact-us' id='contact-us-button'>Contact Us</a>
        </div>
        <div className='menu-button'>
            <Drawer />
        </div>
    </div>
    </div>
    {/* </div> */}
    {/* </div> */}
    {/* </div> */}
    </>
  )
}

// export default header