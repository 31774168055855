import React from 'react'
// import './pregnancy-chiropractic.css'
import Header from '../../landing_page/header';
import Footer from '../../landing_page/footer';
import Pregnancy from '../../images/highlight/pregnancy.png';

export default function pregnancyChiropractic() {
  return (
    <div>
      <Header/>
      <div className='services-container'>
      <div className="large-monitor">
        <div>
          <h1>Pregnancy Chiropractic in Covington</h1>
          <img id='services-images' src={Pregnancy} alt="" />
        </div>
        <div>
          <h3>Pregnancy is a joyful and transformative time, but it can also bring discomfort and significant stress on the body. With limited options for medication, chiropractic care offers a natural way to relieve pain and tension, allowing you to enjoy a more comfortable and healthy pregnancy.</h3>
        </div>
        <div>
          <h1>Relief from Added Weight and Strain</h1>
          <h3>As your baby grows, the increasing weight places additional strain on your body, particularly on the lower back, as your center of gravity shifts forward. This can cause intense discomfort in the muscles and joints along your spine. Chiropractic care can help alleviate this tension, easing pain and promoting better alignment throughout your pregnancy.</h3>
        </div>
        <div className='appointment-today'>
          <a href="/contact-us"><button>Make An Appointment Today</button></a>
        </div>
      </div>
      </div>
      <Footer/>
    </div>
  )
}