import React from 'react'
import './senior-chiropractic.css'
import Header from '../../landing_page/header';
import Footer from '../../landing_page/footer';
import Senior from '../../images/highlight/elderly.png';

export default function seniorChiropractic() {
  return (
    <div>
      <Header/>
      <div className='services-container'>
      <div className="large-monitor">
        <div>
          <h1>Senior Chiropractic in Covington</h1>
          <img id='services-images' src={Senior} alt="" />
        </div>
        <div>
          <h3>As we age, it’s common to experience reduced mobility, joint stiffness, muscle weakness, and chronic pain, which can make enjoying your later years challenging.</h3>
          <h3>At Covington Chiropractic, we’re here to help you stay active and engaged. Instead of spending your days seated in front of a TV or computer, Dr. Chon and his team encourage you to move, exercise, and take part in the activities you love. Our chiropractic adjustments can alleviate pain, allowing you to safely explore new pursuits.</h3>
        </div>
        <div>
          <h1>The Advantages of Chiropractic Care for Seniors</h1>
          <h3>Chiropractic care offers a range of benefits for seniors, helping you maintain independence and enjoy a more active, fulfilling life. These advantages include pain relief, improved mobility, enhanced balance and coordination, and overall better health and wellness.</h3>
        </div>
        <div className='appointment-today'>
          <a href="/contact-us"><button>Make An Appointment Today</button></a>
        </div>
      </div>
      </div>
      <Footer/>
    </div>
  )
}