import React from 'react';
import './chiropractic-care.css';
import Header from '../../landing_page/header';
import Footer from '../../landing_page/footer';
import Care from '../../images/highlight/care.jpg';

export default function chiropracticCare() {
  return (
    <div>
      <Header/>
      <div className='services-container'>
      <div className="large-monitor">
        <div>
          <h1>Discover a Healthier You Through Chiropractic Care</h1>
          <img id='services-images' src={Care} alt="" />
        </div>
        <div>
          <h3>At Covington Chiropractic, we believe that the key to improved health and lasting wellness lies in the benefits of chiropractic adjustments. Our treatments aim to bring relief, restoring balance and helping you avoid the discomfort that can interfere with your daily life.</h3>
        </div>
        <div>
          <h1>A Comprehensive Approach to Healing</h1>
          <h3>Dr. Chon takes the time to thoroughly assess every new patient, carefully examining the body—especially the spine—for misalignments or subluxations.</h3>
          <h3>These issues can disrupt normal function, affecting joints, muscles, tendons, and ligaments, and leading to pain or limited mobility.</h3>
        </div>
        <div>
          <h1>Rebuilding Health, One Step at a Time</h1>
          <h3>Once misalignments and subluxations are identified, Dr. Chon works with you to develop a personalized plan for correction. By sharing his expertise, he empowers you to take an active role in your recovery, ensuring you're fully engaged in the healing process.</h3>
        </div>
        <div className='appointment-today'>
          <a href="/contact-us"><button>Make An Appointment Today</button></a>
        </div>
      </div>
      </div>
      <Footer/>
    </div>
  )
}