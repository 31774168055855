import React from 'react';
import Header from '../landing_page/header';
import Footer from '../landing_page/footer';
import './Meet_Our_Doctors.css';
import Amos from '../images/Dr.-Amos-Young-Won-Chon.jpg';
import Darren from '../images/darren.png';

export default function Meet_Our_Doctors() {
    return (
      <>
      <Header />
      <header className='doctors-header' data-aos="fade-in">
        <h1>Meet Our Doctors</h1>
      </header>
      <div className='meet-our-doctors-container'>
        <a href="/Dr_Amos_Chon" data-aos="fade-in">
          <div className='doctors'>
            <img src={Amos} id='amos' alt="" />
            <div className='title'>
              <h1 id='title'>Dr. Amos Young Won Chon</h1>
            </div>
          </div>
        </a>
        <a href="/Dr_Darren_Siu" data-aos="fade-in">
          <div className='doctors'>
            <img src={Darren} id='amos' alt="" />
            <div className='title'>
              <h1 id='title'>Dr. Darren Siu</h1>
            </div>
          </div>
        </a>
      </div>
      <Footer />
      </>
    )
  }