import React from 'react';
import Header from '../landing_page/header';
import Footer from '../landing_page/footer';
import './Contact_Us.css';
import Gmap from '../component/gmap';
import { useState } from 'react';

import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import { AiFillPhone,AiFillMail } from "react-icons/ai";
import { AiOutlineGlobal } from "react-icons/ai";

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

// npm install react-icons --save

// npm install @mui/material @emotion/react @emotion/styled
// npm install @mui/material @mui/styled-engine-sc styled-components
// npm install @mui/joy @emotion/react @emotion/styled
// npm install @mui/icons-material


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function Contact_Us() {
    const navigate = useNavigate();
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_e4e4vzh', 'template_gylevsy', form.current, {
        publicKey: 'LO0TAvSenbUV6zUmn',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          navigate ('/confirmation');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

    
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  const handleClose = (e) => {
      e.preventDefault();
      setOpen(false);
      window.location.reload();
  };

  const [selectedOption, setSelectedOption] = useState('');

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const [selectedOption2, setSelectedOption2] = useState('');

    const handleRadioChange2 = (event) => {
        setSelectedOption2(event.target.value);
    };
  
  return (
      <>
      <Header />
      <div className='contact-form' id='contact-form'>
      <form ref={form} onSubmit={sendEmail}>
                <div className='contact-page'>
                    <div className='appointment-request-header' data-aos="fade-in">
                        <h1 id='appointment-header'>Appointment Request</h1>
                        <h3>Please fill out our appointment request form below and our
                        friendly staff will be in contact with you soon to schedule
                        your appointment.</h3>
                    </div>
                    <div className='radio-container' data-aos="fade-in">
                    <label className='radio-container2'>
                        <input
                            id='radio'
                            type="radio"
                            name="new"
                            value="yes"
                            checked={selectedOption === 'yes'}
                            onChange={handleRadioChange}
                        />
                        New Patient
                        </label><br />
                        <label className='radio-container2'>
                            <input
                                id='radio'
                                type="radio"
                                name="current"
                                value="yes2"
                                checked={selectedOption === 'yes2'}
                                onChange={handleRadioChange}
                            />
                            Current Patient
                        </label><br />
                        <label className='radio-container2'>
                            <input
                                id='radio'
                                type="radio"
                                name="existing"
                                value="yes3"
                                checked={selectedOption === 'yes3'}
                                onChange={handleRadioChange}
                            />
                            Existing Patient
                        </label><br />
                    </div>
                    {/* <div className='radio-container'>
                        <div className='radio'>
                            <input id='radio' type="radio"/>
                            <h3>New Patient</h3>
                        </div>
                        <div className='radio'>
                            <input id='radio' type="radio"/>
                            <h3>Current Patient</h3>
                        </div>
                        <div className='radio'>
                            <input id='radio' type="radio"/>
                            <h3>Existing Patient</h3>
                        </div>
                    </div> */}
                    
                    <div className='name'>
                        <div className='make-column' data-aos="fade-in" data-aos-duration="2300">
                            {/* <h1 className='contact-input'>First Name</h1> */}
                            <div className='input-boxes' data-aos="fade-in">
                            <input name='first_name' type="text" placeholder='First Name'/>
                            </div>
                        </div>
                        <div className='make-column' data-aos="fade-in" data-aos-duration="2300">
                            {/* <h1 className='contact-input'>Last Name</h1> */}
                            <div className='input-boxes' data-aos="fade-in">
                            <input name='last_name' type="text" placeholder='Last Name'/>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='make-column' data-aos="fade-in" data-aos-duration="2300">
                        {/* <h1 className='contact-input'>Email Address</h1> */}
                        <input id='contact-input3' name='email' type="text" placeholder='Email Address' data-aos="fade-in"/>
                    </div>            
                    <br />
                    <div className='make-column' data-aos="fade-in" data-aos-duration="2300">
                        {/* <h1 className='contact-input'>Phone Number</h1> */}
                        <input id='contact-input3' name='phone' type="text" placeholder='Phone Number' data-aos="fade-in"/>
                    </div>            
                    <br />
                    
                    <div className='radio-container3' data-aos="fade-in">
                        <h3 data-aos="fade-in">Respond to me via:</h3>
                        {/* <input id='radio' type="radio"/>
                        <h3>Email</h3>
                        <input id='radio' type="radio"/>
                        <h3>Phone</h3> */}
                        <label className='radio-container2' data-aos="fade-in">
                            <input
                                id='radio'
                                type="radio"
                                name="email_respond"
                                value="yes4"
                                checked={selectedOption2 === 'yes4'}
                                onChange={handleRadioChange2}
                            />
                            Email
                        </label><br />
                        <label className='radio-container2' data-aos="fade-in">
                            <input
                                id='radio'
                                type="radio"
                                name="phone_respond"
                                value="yes5"
                                checked={selectedOption2 === 'yes5'}
                                onChange={handleRadioChange2}
                            />
                            Phone
                        </label><br />
                    </div>
                    <br />
                    <h3 id='preferred-text' data-aos="fade-in">Preferred Day/Time:</h3>
                    <div className='date-time' data-aos="fade-in">
                        <input name='date' type="date" placeholder='Date'/>
                        <input name='time' type="time" placeholder='Time' min='9:00' max='18:00'/>
                    </div>
                    <br />
                    <div className='make-column' data-aos="fade-in" data-aos-duration="2300">
                        {/* <h1 className='contact-input'>Message</h1> */}
                        <textarea id='contact-input2' maxlength="300" rows='4' type="text" name='message' label="Message" placeholder='Comment/Message'/>
                    </div>
                    <br />
                    
                    <React.Fragment>
                        {/* <Button variant="outlined" onClick={handleClickOpen}>
                            Open dialog
                        </Button> */}
                        <div data-aos="fade-in" data-aos-duration="2300">
                            <button type="submit">Submit</button>
                        </div>
                        <BootstrapDialog
                            onClose={handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                        >
                            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                            <h1>Thank you!</h1>
                            </DialogTitle>
                            <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                            >
                            <CloseIcon />
                            </IconButton>
                            <DialogContent dividers>
                            <Typography gutterBottom>
                                <h3>We will get back to you as soon as possible.</h3>
                            </Typography>
                            </DialogContent>
                            {/* <DialogActions>
                            <Button autoFocus onClick={handleClose}>
                                Save changes
                            </Button>
                            </DialogActions> */}
                        </BootstrapDialog>
                    </React.Fragment>
                </div>
            </form>
            <div className='contact-image' data-aos="fade-in">
                <div className='make-column'>
                    <div data-aos="fade-in" data-aos-duration="2300">
                        <h1 className='about-header'>Contact</h1>
                    </div>
                    <div data-aos="fade-in" data-aos-duration="2300">
                        <h3>Feel free to contact us with any questions.</h3>
                    </div>
                </div>
                <br />
                <div className='footer-info' data-aos="fade-in" data-aos-duration="2300">
                    <a href='tel:2532373165' className='footer-phone'>Phone: (253) 630-9777</a>
                </div>
                <br />
                <div className='footer-info' data-aos="fade-in" data-aos-duration="2300">
                    <div>
                      <a href="https://www.google.com/maps/place/Covington+Chiropractic/@47.3530833,-122.1230778,19z/data=!3m1!4b1!4m6!3m5!1s0x54905fbc456519ad:0x38b4ea4b97943e78!8m2!3d47.3530833!4d-122.1224327!16s%2Fg%2F1tdpfx4r?entry=ttu" className='footer-phone'>27641 Covington Way SE,</a>
                    </div>
                    <a href="https://www.google.com/maps/place/Covington+Chiropractic/@47.3530833,-122.1230778,19z/data=!3m1!4b1!4m6!3m5!1s0x54905fbc456519ad:0x38b4ea4b97943e78!8m2!3d47.3530833!4d-122.1224327!16s%2Fg%2F1tdpfx4r?entry=ttu" className='footer-phone'>Suite 2 Covington, WA 98042</a>
                </div>
                <div className='footer-hours' data-aos="fade-in">
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'></h5>
                        </div>
                        <div>
                            <h5 id='footer-day2'>Morning</h5>
                        </div>
                        <div>
                            <h5>Afternoon</h5>
                        </div>
                        
                    </div>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Monday:</h5>
                        </div>
                        <div>
                            <h5 id='footer-day2'>9:00 - 1:00</h5>
                        </div>
                        <div>
                            <h5>2:30 - 6:00</h5>
                        </div>
                    </div>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Tuesday:</h5>
                        </div>
                        <div>
                            <h5 id='footer-day2'>9:00 - 1:00</h5>
                        </div>
                        <div>
                            <h5>2:30 - 6:00</h5>
                        </div>
                    </div>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Wednesday:</h5>
                        </div>
                        <div>
                            <h5 id='footer-day2'>9:00 - 1:00</h5>
                        </div>
                        <div>
                            <h5>2:30 - 6:00</h5>
                        </div>
                    </div>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Thursday:</h5>
                        </div>
                        <div>
                            <h5 id='footer-day2'>9:00 - 1:00</h5>
                        </div>
                        <div>
                            <h5>2:30 - 6:00</h5>
                        </div>
                    </div>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Friday:</h5>
                        </div>
                        <div>
                            <h5 id='footer-day2'>9:00 - 12:00</h5>
                        </div>
                        <div>
                            <h5>12:00 - 2:00</h5>
                        </div>
                    </div>
                </div>
            </div>
            {/* <form ref={form} onSubmit={sendEmail}> */}
            
        </div>
      <Footer />
      </>
    )
  }