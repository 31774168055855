import React from 'react';
import Header from '../landing_page/header';
import Footer from '../landing_page/footer';
import './Our_Services.css';

import Care from '../images/highlight/care.jpg';

export default function Our_Services() {
    return (
      <>
      <Header />
        <div className='services-header' data-aos="fade-in">
          <h1>Covington Chiropractic Services</h1>
          {/* <h1>See How We Can Help You</h1> */}
        </div>
        
        <div className='our-services-container'>
          <div className='our-services-highlight-box'>
            <a href="/chiropractic-care" data-aos="fade-in">
              <div className="chiropractic-care">
                <h3>Chiropractic Care</h3>
              </div>
            </a>
            <a href="/personal-injury" data-aos="fade-in">
              <div className="personal-injury">
                <h3>Personal Injury</h3>
              </div>
            </a>
            <a href="/sports-chiropractic" data-aos="fade-in">
              <div className="sports-chiropractic">
                <h3>Sports Chiropractic</h3>
              </div>
            </a>
            <a href="/pregnancy-chiropractic" data-aos="fade-in">
              <div className="pregnancy-chiropractic">
                <h3>Pregnancy Chiropractic</h3>
              </div>
            </a>
            <a href="/pediatric-chiropractic" data-aos="fade-in">
              <div className="pediatric-chiropractic">
                <h3>Pediatric Chiropractic</h3>
              </div>
            </a>
            <a href="/senior-chiropractic" data-aos="fade-in">
              <div className="senior-chiropractic">
                <h3>Senior Chiropractic</h3>
              </div>
            </a>
            <a href="/klaser-treatment" data-aos="fade-in">
              <div className="klaser-treatment">
                <h3>K Laser Treatment</h3>
              </div>
            </a>
            <a href="/auto-accident-chiropractic" data-aos="fade-in">
              <div className="auto-accident-chiropractic">
                <h3>Accident Chiropractic</h3>
              </div>
            </a>
            <a href="/contact-us" data-aos="fade-in">
              <div className="contact-us-services">
                <h3>Contact Us</h3>
              </div>
            </a>
          </div>
        <div>
</div>

        </div>
      <Footer />
      </>
    )
  }