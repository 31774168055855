import React from 'react'
import './sports-chiropractic.css'
import Header from '../../landing_page/header';
import Footer from '../../landing_page/footer';
import Sports from '../../images/highlight/sports.webp';

export default function SportsChiropractic() {
  return (
    <div>
      <Header/>
      <div className='services-container'>
      <div className="large-monitor">
        <div>
          <h1>Sports Chiropractic in Covington</h1>
          <img id='services-images' src={Sports} alt="" />
        </div>
        <div>
          
          <h3>Whether you're a runner, CrossFitter, golfer, or cyclist, maintaining your body’s health and optimizing performance is essential. Chiropractic care for athletes can enhance function, reduce the risk of injury, and speed up recovery when injuries do occur.</h3>
          <h3>At our Covington office, we have experience treating athletes of all levels, from high school and collegiate competitors to weekend warriors and marathon runners. Whether you’re recovering from a concussion or aiming to prevent a muscle strain, we’re here to help you perform your best.</h3>
        </div>
        <div>
          <h1>Empowering You to Prevent Injuries</h1>
          <h3>At Covington Chiropractic, we emphasize the preventive benefits of sports chiropractic. Our focus is on correcting dysfunctions before they escalate into injuries, helping you stay active and perform at your peak.</h3>
          <h3>If we detect potential issues, such as leg length discrepancies that could lead to injury, we address them proactively to prevent problems during the intensity of training and competition. Even if you're not experiencing pain now, there may be underlying dysfunctions that need attention.</h3>
        </div>
        <div className='appointment-today'>
          <a href="/contact-us"><button>Make An Appointment Today</button></a>
        </div>
      </div>
      </div>
      <Footer/>
    </div>
  )
}