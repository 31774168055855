import React from 'react';
import './klaser_treatment.css';
import Header from '../../landing_page/header';
import Footer from '../../landing_page/footer';
import LaserContact from '../../images/K Laser Med Email-Template-Header-800x279-1.jpg';
import Laser from '../../images/highlight/laser.jpg';
import LaserBody1 from '../../images/laserposter1.png';
import LaserBody2 from '../../images/laserposter2.png';

export default function klasterTreatment() {
  return (
    <div>
      <Header/>
      <div className='services-container'>
      <div className="large-monitor laser">
        <h1>Klaser Treatment</h1>
        <img id='laser' src={Laser} alt="" />
        <h1>What is K-Laser Therapy?</h1>
        <h3>K-Laser therapy is a cutting-edge, non-invasive treatment designed to reduce pain and promote healing. It utilizes specific wavelengths of light to penetrate deep into tissues, stimulating cellular activity and accelerating the body’s natural healing process. This therapy is effective for treating both acute and chronic conditions, making it a versatile option for a wide range of ailments.</h3>
        <br />
        {/* <img src={LaserBody1} id='laser-poster' alt="" /> */}
        
        <img src={LaserBody2} id='laser-poster' alt="" />
        <h1>Comprehensive Care for Pain and Injuries</h1>
        <h3>K-Laser therapy can be used from head to toe, addressing issues such as headaches, sports injuries, arthritis, and much more. Whether you're recovering from a car accident, dealing with chronic pain, or experiencing muscle soreness after exercise, K-Laser treatments are designed to provide targeted relief. It works effectively on conditions like rotator cuff tears, sciatica, and plantar fasciitis.</h3>
        <h1>Talk to Your Chiropractor</h1>
        <h3>If you're looking for a non-invasive solution to reduce pain and improve healing, talk to your Chiropractor about K-Laser therapy. With proven success in treating conditions from migraines to post-surgery recovery, K-Laser could be the right choice to help diminish your pain and get you back to feeling your best.</h3>
        <div>
          <img id="services-images" src={LaserContact} alt="" />
        </div>
      </div>
      </div>
      <Footer/>
    </div>
  )
}