import React from 'react';
import Header from '../landing_page/header';
import Footer from '../landing_page/footer';
import './Dr_Amos_Chon.css';
import Darren from '../images/darren.png';

export default function Meet_Our_Doctors() {
    return (
      <>
      <Header />
      <div className='dramoschon'>
      <div className='large-monitor'>
        <div className='image-wrap'>
          <img src={Darren} id='amos' alt="" />
          <div>
            <h1>Meet Dr. Darren Siu</h1>
            <h3>Darren Siu DC is a dedicated chiropractic professional specializing in the Activator Method, a precise and gentle approach to spinal adjustments. With a passion for holistic health, he is also deeply immersed in the study of functional medicine.</h3>
          </div>
        </div>
        <div>
          <h1>A Health Conscious Life</h1>
          <h3>Beyond the clinical setting, Darren is an enthusiastic advocate for natural living. He believes that embracing a lifestyle that harmonizes with nature can significantly enhance overall health and vitality.</h3>
          <h3>Whether through nutrition or sustainable practices, Darren strives to embody and promote a balanced, healthy way of life. He is excited to guide others on a journey toward a healthier, more vibrant life, blending chiropractic care and natural living.</h3>
        </div>
        <div>
          <h1>Interests of Dr. Darren</h1>
          <h3>You can find Darren enjoying an exploratory drive around nature or in a boba store.</h3>
        </div>
        <div>
          <h1>Get on the Healing Path Today</h1>
          <h3>Experience the benefits of Dr. Darren's extensive knowledge and compassionate care. Schedule an appointment today!</h3>
          <a href="/contact-us"><button>Contact Us</button></a>
        </div>
      </div>
      </div>
      <Footer />
      </>
    )
  }