import React from 'react'
import './personal-injury.css'
import Header from '../../landing_page/header';
import Footer from '../../landing_page/footer';
import Personal from '../../images/highlight/personal.png';

export default function personalInjury() {
  return (
    <div>
      <Header/>
      <div className='services-container'>
      <div className="large-monitor">
        <div>
          <h1>Personal Injury (PIP) in Covington</h1>
          <img id='services-images' src={Personal} alt="" />
        </div>
        <div>
          <h3>At Covington Chiropractic, we understand the physical and emotional toll that follows an injury. Whether it's from a car accident or workplace incident, our compassionate team is here to help you recover, restore your health, and regain your sense of well-being.</h3>
        </div>
        <div>
          <h1>Supporting You Through Difficult Times</h1>
          <h3>Many personal injuries, such as those from auto accidents or work-related incidents, may qualify for insurance-covered care. Our friendly staff in Covington is ready to assist, answering any questions you may have about your coverage and eligibility.</h3>
          <h3>Even if you aren’t experiencing immediate pain, it’s still beneficial to have an examination after a traumatic event. Injuries like car accidents can misalign your body, and chiropractic care can help ensure everything is back in place, preventing future issues from arising.</h3>
        </div>
        <div className='appointment-today'>
          <a href="/contact-us"><button>Make An Appointment Today</button></a>
        </div>
      </div>
      </div>
      <Footer/>
    </div>
  )
}