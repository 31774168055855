import React from 'react';
import Header from '../landing_page/header';
import Footer from '../landing_page/footer';
import './Contact_Us.css';
import Gmap from '../component/gmap';
import { useState } from 'react';

import { useRef } from 'react';


export default function Confirmation() {

  return (
      <>
      <Header />
        <div className='confirmation'>
        <h1>Thank You</h1>
        <h3>We will get back to you as soon as possible!</h3>
        </div>
      <Footer />
      </>
    )
  }