// npm install @mui/joy @emotion/react @emotion/styled
// npm install @fontsource/inter

import * as React from 'react';
import Box from '@mui/joy/Box';
import Drawer from '@mui/joy/Drawer';
import Button from '@mui/joy/Button';
import List from '@mui/joy/List';
import Divider from '@mui/joy/Divider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import { Link } from 'react-router-dom';
import Hamburger from 'hamburger-react';

import './Drawer.css';

export default function DrawerBasic() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (inOpen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(inOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Hamburger toggled={open} toggle={() => setOpen(!open)} />
      <Drawer size="sm" open={open} onClose={toggleDrawer(false)}>
        <Box
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
        <List>
            {['Home'].map((text) => (
              <ListItem key={text}>
                <ListItemButton id='list-item' component={Link} to={`/`}>
                  {text}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider/>
          <List>
            {['About Us', 'Meet Our Doctors', 'Our Services', 'New Patients'].map((text) => (
              <ListItem key={text}>
                <ListItemButton id='list-item' component={Link} to={`/${text.toLowerCase().replace(/\s+/g, '-')}`}>
                  {text}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider/>
          <List>
            {['Contact Us'].map((text) => (
              <ListItem key={text}>
                <ListItemButton id='list-item' component={Link} to={`/${text.toLowerCase().replace(/\s+/g, '-')}`}>
                  {text}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          
        </Box>
      </Drawer>
    </Box>
  );
}