import React from 'react';
import Header from '../landing_page/header';
import Footer from '../landing_page/footer';
import './About_Us.css';
import Office from '../images/front-office.jpg';
import BackOffice from '../images/back-office.jpg';

export default function About_Us() {
  return (
    <>
    <Header />
    <div className='about-us-container'>
    <div className='large-monitor'>
      <div data-aos="fade-in">
        <h1>About Covington Chiropractic</h1>
        <img id='services-images' src={Office} alt="" />
      </div>
      <div data-aos="fade-in">
        
        <h3>At Covington Chiropractic, our mission is to provide chiropractic care that promotes health and well-being for every patient who walks through our doors. Whether you’re seeking immediate pain relief or looking to prevent future injuries, our Covington office, also serving Kent, is here to help.</h3>
        <h3>Dr. Amos Young Won Chon, the owner and lead chiropractor, has taken over from the now-retired Dr. Neal Vitalis. Dr. Chon is dedicated to helping you achieve lasting health and recovery. Our team is committed to offering personalized care and valuable education to support your long-term wellness.</h3>
      </div>
      <div data-aos="fade-in">
        <h1>Helping You Live Your Best Life at Every Stage</h1>
        <h3>We offer specialized chiropractic care for seniors, pregnant women, and children, welcoming patients of all ages and life stages. Whether you're a newborn, a senior, or anywhere in between, it's never too early or too late to benefit from gentle, effective therapies or to learn techniques that can enhance your health at home.</h3>
        <h3>Chiropractic care provides non-invasive treatments that can help you feel better almost instantly, no matter where you are in life.</h3>
      </div>
      <a href="/our-services" data-aos="fade-in"><button>Our Services</button></a>
      <div data-aos="fade-in">
        <h1>Master the Techniques for Long-Term Success</h1>
        <img id='services-images' src={BackOffice} alt="" />
      </div>
      <div data-aos="fade-in">
        
        <h3>As a strong advocate of lifelong learning, Dr. Chon is committed to teaching you how to care for your body. He provides personalized lifestyle advice, along with exercises and stretches tailored to your specific needs. Dr. Chon can also guide you on proper ergonomics to improve your work environment.</h3>
        <h3>In addition, he carefully evaluates your posture and offers solutions to improve it through support and education. Dr. Chon can even provide custom recommendations for better sleeping posture, including general mattress advice, which can make a noticeable difference in your overall well-being.</h3>
      </div>
      <div data-aos="fade-in">
        <h1>Schedule an appointment today</h1>
        {/* <h3>Experience our extensive knowledge and compassionate care. Contact us today to schedule an appointment!</h3> */}
        <a href="/contact-us"><button>Contact Us</button></a>
      </div>
    </div>
    </div>
    <Footer />
    </>
  )
}
