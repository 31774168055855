import React from 'react'
import './auto-accident-chiropractic.css'
import Header from '../../landing_page/header';
import Footer from '../../landing_page/footer';
import Accident from '../../images/highlight/auto.jpg'

export default function AutoAccidentChiropractic() {
  return (
    <>
    <Header />
    <div className='services-container'>
    <div className="large-monitor">
      <div>
        <h1>Auto Accident Chiropractic in Covington</h1>
        <img id='services-images' src={Accident} alt="" />
      </div>
      <div>
        
        <h3>Whether you’ve been in a minor fender bender or a high-speed collision, your body has likely experienced trauma that requires prompt attention. That’s why we encourage you to reach out to our practice as soon as possible after your accident for a thorough assessment and care.</h3>
      </div>
      <div>
        <h1>Common Injuries to Watch For</h1>
        <h3>Whiplash is one of the most frequent injuries we treat at our clinic. It occurs when the soft tissues around the spine are damaged, often from the sudden force of an accident. If you've been in a car crash, you may have experienced significant neck trauma from whiplash, leading to neck pain, stiffness, and back pain.</h3>
        <h3>Additionally, disc herniations are another common injury following an accident, which Dr. Chon has firsthand experience with after his own car crash. Disc herniation symptoms include numbness, radiating pain down the arms and legs, and loss of sensation. Chiropractic care can help address these issues, promoting recovery and pain relief.</h3>
      </div>
      <div className='appointment-today'>
        <a href="/contact-us"><button>Make An Appointment Today</button></a>
      </div>
    </div>
    </div>
    <Footer />
    </>
  )
}
