import React from 'react'
import './pediatric-chiropractic.css'
import Header from '../../landing_page/header';
import Footer from '../../landing_page/footer';
import Peds from '../../images/highlight/peds.jpg';

export default function pediatricChiropractic() {
  return (
    <div>
      <Header/>
      <div className='services-container'>
      <div className="large-monitor">
        <div>
          <h1>Pediatric Chiropractic in Covington</h1>
          <img id='services-images' src={Peds} alt="" />
        </div>
        <div>
          
          <h3>At Covington Chiropractic, we warmly welcome children of all ages, from newborns to toddlers and teens. Starting chiropractic care early can support brain development, enhance motor skills, and promote overall pediatric health. Just as with our adult patients, we’re passionate about helping kids feel their best and stay healthy.</h3>
        </div>
        <div>
          <h1>Building a Foundation for Lifelong Health</h1>
          <h3>Chiropractic care in childhood can play a key role in supporting long-term health. By addressing misalignments and subluxations early on, we can prevent potential issues from taking root, helping your child’s body function at its best as they grow into adulthood. This proactive care can set the stage for a lifetime of optimal health.</h3>
        </div>
        <div className='appointment-today'>
          <a href="/contact-us"><button>Make An Appointment Today</button></a>
        </div>
      </div>
      </div>
      <Footer/>
    </div>
  )
}